import * as React from "react"
import { Link } from "gatsby"

import logo from "../images/boundary-logo.svg"

const links = [
  {
    link: '/blog',
    title: 'Blog',
    description: 'News and how to guides by Boundary',
    icon: () => (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
    )
  },
]

const NotFoundPage = () => {
  return (
    <div className="min-h-screen bg-primary-900 text-white">
      <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 pt-16">
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="Logo"
          />
        </div>

        <div className="max-w-xl mx-auto py-16 sm:py-24">
          <div className="text-center">
            <p className="text-base font-semibold">404</p>

            <h1 className="mt-2 text-4xl font-semibold tracking-tight sm:text-5xl sm:tracking-tight">
              Page not found
            </h1>

            <p className="mt-2 text-lg text-primary-200">The page you are looking for could not be found</p>
          </div>

          <div className="mt-12">
            <h2 className="text-base font-semibold text-primary-400">Popular pages</h2>

            <ul role="list" className="mt-4 border-t border-b border-primary-600 divide-y divide-primary-600">
              {links.map((link, linkIdx) => (
                <li key={linkIdx} className="relative py-6 flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-primary-700">
                      <link.icon className="h-6 w-6" aria-hidden="true" />
                    </span>
                  </div>

                  <div className="min-w-0 flex-1">
                    <h3 className="text-base">
                      <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                        <Link to={link.link} className="focus:outline-none">
                          <span className="absolute inset-0" aria-hidden="true" />
                          {link.title}
                        </Link>
                      </span>
                    </h3>

                    <p className="text-base text-primary-200">{link.description}</p>
                  </div>

                  <div className="flex-shrink-0 self-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                    </svg>
                  </div>
                </li>
              ))}
            </ul>

            <div className="mt-8">
              <Link href="/" className="text-base font-medium">
                Or go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export const Head = () => <>
  <title>Not found - Boundary CMS</title>

  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
  <link rel="manifest" href="/site.webmanifest" />
  <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#515BE7" />
  <meta name="msapplication-TileColor" content="#515BE7" />
  <meta name="theme-color" content="#ffffff" />
</>

export default NotFoundPage
